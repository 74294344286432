<template>
  <v-app class="background">
    <Toolbar />
    <v-container>
      <v-layout row wrap justify-center align-center pt-16>
        <v-flex xs10 lg4 pa-2>
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="mx-auto"
              max-width="344"
            >
              <v-card-title primary-title>
                <v-img
                  data-aos="fade-up"
                  data-aos-easing="ease-in-sine"
                  src="@/assets/img/FudgeBrownie.jpg"
                ></v-img>
              </v-card-title>
              <v-card-actions class="pb-6">
                <v-layout row wrap justify-center align-center>
                  <v-flex xs10 lg6>
                    <v-btn
                      block
                      outlined
                      rounded
                      color="brown accent-4"
                      @click="closeBrownie = true"
                    >
                      Fudge Brownie
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>

              <v-expand-transition>
                <v-card
                  v-if="closeBrownie"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%"
                >
                  <v-card-text class="pb-0">
                    <p class="text-h4 brown--text">Fudge Brownie</p>
                    <p
                      class="brown--text text-left pt-4"
                    >
                      <b> Fudge Brownies</b> ด้วยรสชาติช็อกโกแลต 70% เข้มข้น
                      ทำให้ได้รสหวานนัวของช็อกโกแลตอันโดดเด่น บวกกับอัตราส่วนแป้งและน้ำตาลที่น้อยลง
                      ทำให้ได้รสชาติช็อกโกแลตแบบเต็มๆ ใครชอบบราวนี่ที่มีความชิววี่ ตอบโจทย์แน่นอนค่ะ
                      PS. บราวนี่ของที่ร้านสามารถทานเย็นๆได้เลยนะคะ ไม่ควรนำไปอบก่อน
                      เนื่องจากมีอัตราส่วนช็อกโกแลตเยอะ อาจทำให้เนื้อละลายได้ค่ะ
                      ฝากมาเตือนกันไว้ก่อน
                    </p>
                  </v-card-text>
                  <v-card-actions class="pt-3">
                    <v-btn outlined rounded color="brown accent-4" @click="closeBrownie = false">
                      ปิด
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-flex>
        <v-flex xs10 lg4 pa-2>
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="mx-auto"
              max-width="344"
            >
              <v-card-title primary-title>
                <v-img
                  data-aos="fade-up"
                  data-aos-easing="ease-in-sine"
                  src="@/assets/img/ChewyChocChips.jpg"
                ></v-img>
              </v-card-title>
              <v-card-actions class="pb-6">
                <v-layout row wrap justify-center align-center>
                  <v-flex xs10 lg6>
                    <v-btn block outlined rounded color="brown accent-4" @click="closeChewy = true">
                      Chewy Choc Chips
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>

              <v-expand-transition>
                <v-card
                  v-if="closeChewy"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%"
                >
                  <v-card-text class="pb-0">
                    <p class="text-h4 brown--text">Chewy Choc Chips</p>
                    <p
                      class="brown--text text-left pt-4"
                    >
                      แป้งสาลีนำเข้าจากฝรั่งเศส T45 และ T55 ที่เรานำมาใช้ทำคุกกี้
                      จุดเด่นคือเป็นแป้งไม่ฟอกสี จึงทำให้แป้งมีความหอมมากกว่าแป้งของไทย เวลาอบเสร็จ
                      เนื้อคุกกี้จะมีความเบาฟูและได้ texture หนึบๆ ส่วนตัวไส้ช็อกโกแลตด้านใน เราใช้
                      70% Dark chocolate ของ #Valrhona กับ #CocaoBarry
                      ถ้าใครชอบไส้ช็อกโกแลตลาวาแบบเยิ้มๆ แนะนำให้นำเข้าเวฟ 30 วินาทีนะคะ
                    </p>
                  </v-card-text>
                  <v-card-actions class="pt-3">
                    <v-btn outlined rounded color="brown accent-4" @click="closeChewy = false">
                      ปิด
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-flex>
        <v-flex xs10 lg4 pa-2>
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="mx-auto"
              max-width="344"
            >
              <v-card-title primary-title>
                <v-img
                  data-aos="fade-up"
                  data-aos-easing="ease-in-sine"
                  src="@/assets/img/DuoChocChips.jpg"
                ></v-img>
              </v-card-title>
              <v-card-actions class="pb-6">
                <v-layout row wrap justify-center align-center>
                  <v-flex xs10 lg6>
                    <v-btn block outlined rounded color="brown accent-4" @click="closeDuo = true">
                      Duo Choc Chips
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>

              <v-expand-transition>
                <v-card
                  v-if="closeDuo"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%"
                >
                  <v-card-text class="pb-0">
                    <p class="text-h4 brown--text">Duo Choc Chips</p>
                    <p
                      class="brown--text text-left pt-4"
                    >
                      <b> Duo Choc Cookie</b> ชื่อนี้มาจากการนำ Dark chocolate 2 ตัวมารวมกัน คือ 70% Dark
                      chocolate ของ #Valrhona กับ #CocaoBarry ซึ่งเป็นแบรนด์ระดับพรีเมียม
                      ก่อนทานแนะนำให้อุ่นสักหน่อย เพื่อให้ได้แป้งนุ่มๆ และไส้ช็อกโกแลตด้านในเยิ้มๆ
                      เหมือนเพิ่งอบเสร็จออกมาจากเตา หน้าตาน่าทาน ตามแบบฉบับ rest is more
                      ความอร่อยบรรยายไม่ได้ค่ะ ลองทานเองแล้วจะรู้ค่ะ
                    </p>
                  </v-card-text>
                  <v-card-actions class="pt-3">
                    <v-btn outlined rounded color="brown accent-4" @click="closeDuo = false">
                      ปิด
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-center align-center pt-10>
        <v-flex xs10 lg4 pa-2>
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="mx-auto"
              max-width="344"
            >
              <v-card-title primary-title>
                <v-img
                  data-aos="fade-up"
                  data-aos-easing="ease-in-sine"
                  src="@/assets/img/ChoccachipBanana.jpg"
                ></v-img>
              </v-card-title>
              <v-card-actions class="pb-6">
                <v-layout row wrap justify-center align-center>
                  <v-flex xs10 lg6>
                    <v-btn
                      block
                      outlined
                      rounded
                      color="brown accent-4"
                      @click="closeBanana = true"
                    >
                      Choccachip Banana
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>

              <v-expand-transition>
                <v-card
                  v-if="closeBanana"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%"
                >
                  <v-card-text class="pb-0">
                    <p class="text-h4 brown--text">Choccachip Banana</p>
                    <p
                      class="brown--text text-left pt-4"
                    >
                     <b> Homemade Choccachip Banana</b><br />
                      เทคนิคในการทำ #เค้กกล้วยหอม คือ ต้องเลือกกล้วยหอมที่สุกงอม
                      เพื่อให้ได้รสกล้วยที่หวานฉ่ำตามธรรมชาติ และการใส่กล้วยหอมแบบไม่กั๊ก
                      ก็ช่วยให้เนื้อเค้กได้กลิ่นของกล้วยที่เด่นชัด
                      โดยที่เราไม่ต้องใช้กลิ่นสังเคราะห์เลยแม้แต่น้อย
                      เคี้ยวแล้วได้เนื้อกล้วยแบบเต็มๆ พร้อมดาร์กช็อกโกแลต คุณภาพเยี่ยมจากฝรั่งเศส
                      ที่นำมาจับคู่กันก็แสนจะลงตัว <br />
                      - ถ้าจะมีใครแอบทานแต่ดาร์กช็อกโกแลตไปก่อน ก็ไม่ว่ากันนะคะ -
                    </p>
                  </v-card-text>
                  <v-card-actions class="pt-3">
                    <v-btn outlined rounded color="brown accent-4" @click="closeBanana = false">
                      ปิด
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-flex>
        <v-flex xs10 lg4 pa-2>
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="mx-auto"
              max-width="344"
            >
              <v-card-title primary-title>
                <v-img
                  data-aos="fade-up"
                  data-aos-easing="ease-in-sine"
                  src="@/assets/img/BaelFruitCake.jpg"
                ></v-img>
              </v-card-title>
              <v-card-actions class="pb-6">
                <v-layout row wrap justify-center align-center>
                  <v-flex xs10 lg6>
                    <v-btn block outlined rounded color="brown accent-4" @click="closeBael = true">
                      Bael Fruit Cake
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>

              <v-expand-transition>
                <v-card
                  v-if="closeBael"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%"
                >
                  <v-card-text class="pb-0">
                    <p class="text-h4 brown--text">Bael Fruit Cake</p>
                    <p
                      class="brown--text text-left pt-4"
                    >
                      <b>เค้กมะตูม</b>  ขนมที่อาจจะไม่ได้เป็นที่นิยมมากนัก
                      แต่เราว่ามะตูมมีเสน่ห์ในตัวมันเองนะ โดยมะตูมเชื่อม ที่ทางร้านเลือกใช้
                      เดินทางมาไกลจากจังหวัดเชียงใหม่ เสน่ห์ของเค้กมะตูมคือเนื้อมะตูมที่คงความนุ่ม
                      มีความฉ่ำ ไม่แห้ง เมื่อได้ทางคู่กับเนื้อ sponge cake
                      จึงทำให้ตัวมะตูมเชื่อมด้านบนไม่หวานจนเกินไป เป็นหวานละมุนๆแบบลงตัว
                      ใครไม่ชอบทานหวานก็สามารถทานได้แบบสบายๆค่ะ
                    </p>
                  </v-card-text>
                  <v-card-actions class="pt-3">
                    <v-btn outlined rounded color="brown accent-4" @click="closeBael = false">
                      ปิด
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-flex>
        <v-flex xs10 lg4 pa-2>
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="mx-auto"
              max-width="344"
            >
              <v-card-title primary-title>
                <v-img
                  data-aos="fade-up"
                  data-aos-easing="ease-in-sine"
                  src="@/assets/img/Financier.jpg"
                ></v-img>
              </v-card-title>
              <v-card-actions class="pb-6">
                <v-layout row wrap justify-center align-center>
                  <v-flex xs10 lg6>
                    <v-btn
                      block
                      outlined
                      rounded
                      color="brown accent-4"
                      @click="closeFinancier = true"
                    >
                      Financier
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>

              <v-expand-transition>
                <v-card
                  v-if="closeFinancier"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%"
                >
                  <v-card-text class="pb-0">
                    <p class="text-h4 brown--text">Financier</p>
                    <p
                      class="brown--text text-left pt-4"
                    >
                     <b>Financier</b>  ขนมทานเล่นดั้งเดิมของฝรั่งเศส ที่เรานำเนยไปตั้งไฟอ่อนๆ จนตกกระกอน
                      กลายเป็นสีน้ำตาลอ่อนๆ หรือที่เราเรียกว่า Brown Butter ซึ่งความพิเศษของ Brown
                      Butter คือเนยจะมีรสชาติออกรส nutty เมื่อนำไปผสมกับตัวแป้งนำเข้าจากฝรั่งเศส
                      (T45) จึงทำให้เนื้อขนมมีทั้งความหอม ความฉ่ำของเนย และได้รสที่นุ่มนวล อีกทั้ง
                      Texture ของฟินองเซียจะมีความหนึบด้านใน แต่ขอบด้านนอกมีความกรอบเล็กน้อย
                      จึงทำให้ฟินองเซียได้เป็นขนมที่มีเสน่ห์เฉพาะตัว
                    </p>
                  </v-card-text>
                  <v-card-actions class="pt-3">
                    <v-btn outlined rounded color="brown accent-4" @click="closeFinancier = false">
                      ปิด
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-hover>
          <!-- <v-hover v-slot="{ hover }" open-delay="200">
            <v-card max-width="350" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }">
              <v-card-text data-aos="fade-up" data-aos-easing="ease-in-sine">
                <v-img src="@/assets/img/Financier.jpg"></v-img>
              </v-card-text>
            </v-card>
          </v-hover>
          <v-card
            style="position: absolute; top: 1050px; left: 947px; border-radius: 5px"
            class="mx-auto"
            max-width="290"
            height="330"
          >
            <v-card-title class="fontHeader" data-aos="fade-up" data-aos-easing="ease-in-sine">
              Financier</v-card-title
            >

            <v-card-subtitle
              class="brown--text text-left pt-4"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
            >
              Financier ขนมทานเล่นดั้งเดิมของฝรั่งเศส ที่เรานำเนยไปตั้งไฟอ่อนๆ จนตกกระกอน
              กลายเป็นสีน้ำตาลอ่อนๆ หรือที่เราเรียกว่า Brown Butter ซึ่งความพิเศษของ Brown Butter
              คือเนยจะมีรสชาติออกรส nutty เมื่อนำไปผสมกับตัวแป้งนำเข้าจากฝรั่งเศส (T45)
              จึงทำให้เนื้อขนมมีทั้งความหอม ความฉ่ำของเนย และได้รสที่นุ่มนวล อีกทั้ง Texture
              ของฟินองเซียจะมีความหนึบด้านใน แต่ขอบด้านนอกมีความกรอบเล็กน้อย
              จึงทำให้ฟินองเซียได้เป็นขนมที่มีเสน่ห์เฉพาะตัว
            </v-card-subtitle>
          </v-card> -->
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-center align-center pt-10 pb-16>
        <v-flex xs10 lg4 pa-2>
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="mx-auto"
              max-width="344"
            >
              <v-card-title primary-title>
                <v-img
                  data-aos="fade-up"
                  data-aos-easing="ease-in-sine"
                  src="@/assets/img/SCONE.jpg"
                ></v-img>
              </v-card-title>
              <v-card-actions class="pb-6">
                <v-layout row wrap justify-center align-center>
                  <v-flex xs10 lg6>
                    <v-btn block outlined rounded color="brown accent-4" @click="closeScone = true">
                      SCONE
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>

              <v-expand-transition>
                <v-card
                  v-if="closeScone"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%"
                >
                  <v-card-text class="pb-0">
                    <p class="text-h4 brown--text">SCONE</p>
                       <p class="text-h6 brown--text"> Original Scone / Cranberry Scone</p>
                    <p
                      class="brown--text text-left pt-4"
                    >
                      แป้งฝรั่งเศสนำเข้าที่เรานำมาทำสโคน (T45,T55) ช่วยให้เนื้อสโคนมีความหอม นุ่ม ฟู
                      และเนยเกรดพรีเมียร์จากฝรั่งเศส Elle et vire ช่วยให้เนื้อสโคนมีความฉ่ำ
                      ไม่แห้งจนเกินไป เมื่อนำไปอบร้อนก่อนทาน ด้านนอกจะมีความกรอบ
                      แนะนำให้ทานคู่กับซิกเนเจอร์ครีม และ แยมสตอเบอรี่ที่เราทำเอง ความมันจากครีม
                      ช่วยชูรสและกลิ่นของเนยให้หอมมากยิ่งขึ้น
                    </p>
                  </v-card-text>
                  <v-card-actions class="pt-3">
                    <v-btn outlined rounded color="brown accent-4" @click="closeScone = false">
                      ปิด
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-hover>
          <!-- <v-hover v-slot="{ hover }" open-delay="200">
            <v-card max-width="350" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }">
              <v-card-text data-aos="fade-up" data-aos-easing="ease-in-sine">
                <v-img src="@/assets/img/SCONE.jpg"></v-img>
              </v-card-text>
            </v-card>
          </v-hover>
          <v-card
            style="position: absolute; top: 1750px; left: 158px; border-radius: 5px"
            class="mx-auto"
            max-width="290"
            height="330"
          >
            <v-card-title class="fontHeader" data-aos="fade-up" data-aos-easing="ease-in-sine">
              SCONE <br />
            </v-card-title>
            <v-card-subtitle class="fontSub brown--text">
              Original Scone / Cranberry Scone
            </v-card-subtitle>
            <v-card-subtitle
              class="brown--text text-left pt-4"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
            >
              แป้งฝรั่งเศสนำเข้าที่เรานำมาทำสโคน (T45,T55) ช่วยให้เนื้อสโคนมีความหอม นุ่ม ฟู
              และเนยเกรดพรีเมียร์จากฝรั่งเศส Elle et vire ช่วยให้เนื้อสโคนมีความฉ่ำ ไม่แห้งจนเกินไป
              เมื่อนำไปอบร้อนก่อนทาน ด้านนอกจะมีความกรอบ แนะนำให้ทานคู่กับซิกเนเจอร์ครีม และ
              แยมสตอเบอรี่ที่เราทำเอง ความมันจากครีม ช่วยชูรสและกลิ่นของเนยให้หอมมากยิ่งขึ้น
            </v-card-subtitle>
          </v-card> -->
        </v-flex>
      </v-layout>
    </v-container>
    <Footer />
  </v-app>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {
    Toolbar,
    Footer
  },
  data: () => ({
    closeBrownie: false,
    closeChewy: false,
    closeDuo: false,
    closeBanana: false,
    closeBael: false,
    closeFinancier: false,
    closeScone: false
  })
}
</script>
<style scoped>
.fontHeader {
  font-size: 22px;
  color: #9c7656;
}
.fontSub {
  font-size: 18px;
}
.brown--text {
  font-size: 14px;
  color: #9c7656;
}
.background {
  background-color: #edede7;
}
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
